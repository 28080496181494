.jobs {
}

.search {
  width: 100%;
}

.location {
  width: 100%;
  margin-top: 1.5rem;
}

.category {
  width: 100%;
  margin-top: 1.5rem;
}

.job-type {
  width: 100%;
  margin-top: 1.5rem;
}

.card-left img {
  width: 70px;
  height: 70px;
  border-radius: 5px;

  object-fit: cover;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: wheat;
}
::-webkit-scrollbar-thumb {
  background-color: gray;
}
.job-left {
  position: relative;
  top: 3rem;
  width: 100%;

  padding-inline: 10px;
  padding-bottom: 3rem;
}

.job-right {
  width: 100%;
}
.title {
  font-size: 21px;
}

@media (min-width: 992px) {
  .job-right {
    width: 95%;
  }
  .job-left {
    position: fixed;
    width: 50%;
  }

  .search {
    margin: 3rem auto 2rem auto;
    width: 60%;
  }

  .location {
    width: 60%;
    margin: 0rem auto 2rem auto;
  }

  .category {
    width: 60%;
    margin: 0rem auto 2rem auto;
  }

  .job-type {
    width: 60%;
    margin: 0rem auto 2rem auto;
  }
}
